import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "interest-table-component-container" },
    [
      _c("AllocationsTableTabs", {
        on: {
          changeAllocationTab: function (tab) {
            return _vm.changeAllocationTab(tab)
          },
        },
      }),
      _c(
        "div",
        [
          _c(
            VForm,
            {
              staticClass: "interest-table-component-filter-container",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.getAllocationsByPage($event)
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "interests-table-component-filters-label" },
                    [_vm._v(_vm._s(_vm.$t("search_label")))]
                  ),
                  _c(VTextField, {
                    staticClass:
                      "interests-table-component-filters-auto-complete",
                    attrs: { color: "primary", dense: "", outlined: "" },
                    model: {
                      value: _vm.searchInput,
                      callback: function ($$v) {
                        _vm.searchInput = $$v
                      },
                      expression: "searchInput",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "interests-table-component-filters-label" },
                    [_vm._v(_vm._s(_vm.$tc("company", 1)))]
                  ),
                  _c(VAutocomplete, {
                    staticClass:
                      "interests-table-component-filters-auto-complete",
                    attrs: {
                      disabled: _vm.filterByRound,
                      items: _vm.companiesOptions,
                      "item-text": "Name",
                      "item-value": "CompanyId",
                      color: "primary",
                      dense: "",
                      label: _vm.$tc("all_label", 1),
                      outlined: "",
                    },
                    model: {
                      value: _vm.selectedCompany,
                      callback: function ($$v) {
                        _vm.selectedCompany = $$v
                      },
                      expression: "selectedCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "span",
                    { staticClass: "interests-table-component-filters-label" },
                    [_vm._v(_vm._s(_vm.$t("rounds_state_title")))]
                  ),
                  _c(VAutocomplete, {
                    staticClass:
                      "interests-table-component-filters-auto-complete",
                    attrs: {
                      items: _vm.allocationStatusItems,
                      "item-text": _vm.translateAllocationsStatus,
                      "item-value": "value",
                      color: "primary",
                      dense: "",
                      label: _vm.$tc("all_label", 2),
                      outlined: "",
                    },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function ($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus",
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _c(
                  "span",
                  { staticClass: "interests-table-component-filters-label" },
                  [_vm._v(_vm._s(_vm.$t("rounds_time_title")))]
                ),
                _c(
                  "div",
                  { staticClass: "allocation-date-flex-items" },
                  [
                    _c(
                      VMenu,
                      {
                        ref: "startMenuDateRef",
                        attrs: {
                          "close-on-content-click": false,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  VTextField,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["##/##/####"],
                                            expression: "['##/##/####']",
                                          },
                                        ],
                                        staticClass: "rounds-filters-date",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          color: "#AFAFAF",
                                          "background-color": "white",
                                          dense: "",
                                          outlined: "",
                                          "append-icon": "mdi-calendar",
                                          label: "DD/MM/AA",
                                          height: "34",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            _vm.allocationStartDate =
                                              _vm.formatDateOnBlur(
                                                _vm.allocationStartDateText
                                              )
                                          },
                                        },
                                        model: {
                                          value: _vm.allocationStartDateText,
                                          callback: function ($$v) {
                                            _vm.allocationStartDateText = $$v
                                          },
                                          expression: "allocationStartDateText",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.allocationStartMenuDate,
                          callback: function ($$v) {
                            _vm.allocationStartMenuDate = $$v
                          },
                          expression: "allocationStartMenuDate",
                        },
                      },
                      [
                        _c(VDatePicker, {
                          attrs: {
                            locale: "pt-BR",
                            color: "primary",
                            min: "1850-01-01",
                          },
                          on: { change: _vm.saveAllocationStartDate },
                          model: {
                            value: _vm.allocationStartDate,
                            callback: function ($$v) {
                              _vm.allocationStartDate = $$v
                            },
                            expression: "allocationStartDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "allocation-date-item-space-letter" },
                      [_vm._v("a")]
                    ),
                    _c(
                      VMenu,
                      {
                        ref: "endMenuDateRef",
                        attrs: {
                          "close-on-content-click": false,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "auto",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  VTextField,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["##/##/####"],
                                            expression: "['##/##/####']",
                                          },
                                        ],
                                        staticClass: "rounds-filters-date",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          color: "#AFAFAF",
                                          "background-color": "white",
                                          dense: "",
                                          outlined: "",
                                          "append-icon": "mdi-calendar",
                                          label: "DD/MM/AA",
                                          height: "34",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            _vm.allocationFinishDate =
                                              _vm.formatDateOnBlur(
                                                _vm.allocationFinishDateText
                                              )
                                          },
                                        },
                                        model: {
                                          value: _vm.allocationFinishDateText,
                                          callback: function ($$v) {
                                            _vm.allocationFinishDateText = $$v
                                          },
                                          expression:
                                            "allocationFinishDateText",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.allocationEndMenuDate,
                          callback: function ($$v) {
                            _vm.allocationEndMenuDate = $$v
                          },
                          expression: "allocationEndMenuDate",
                        },
                      },
                      [
                        _c(VDatePicker, {
                          attrs: {
                            locale: "pt-BR",
                            color: "primary",
                            min: "1850-01-01",
                          },
                          on: { change: _vm.saveAllocationEndDate },
                          model: {
                            value: _vm.allocationFinishDate,
                            callback: function ($$v) {
                              _vm.allocationFinishDate = $$v
                            },
                            expression: "allocationFinishDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "interest-table-component-button-filter" },
                [
                  _c("NewDxaButton", {
                    attrs: {
                      type: "submit",
                      outline: true,
                      title: _vm.$tc("filter_kpi", 1),
                      loading: _vm.loading,
                    },
                    on: { btnFunction: _vm.getRoundByPage },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "box-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          )
        : _c(VSimpleTable, [
            _c("thead", { staticClass: "interest-table-thead" }, [
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$tc("investor", 1)) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.INVESTOR
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.INVESTOR
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(_vm.orderFilterEnum.INVESTOR)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("date")) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.DATE
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.DATE
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(_vm.orderFilterEnum.DATE)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("value")) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.VALUE
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.VALUE
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(_vm.orderFilterEnum.VALUE)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              !_vm.filterByRound
                ? _c("th", { staticClass: "text-left" }, [
                    _c(
                      "div",
                      { staticClass: "table-thead-flex" },
                      [
                        _vm._v(" " + _vm._s(_vm.$tc("company", 1)) + " "),
                        _c("font-awesome-icon", {
                          staticClass: "arrow-table-icon",
                          attrs: {
                            color:
                              "" +
                              (_vm.changeIconOrder ===
                              _vm.orderFilterEnum.COMPANY
                                ? "var(--primary)"
                                : "#AFAFAF"),
                            icon:
                              "fa-solid " +
                              (_vm.changeIconOrder ===
                              _vm.orderFilterEnum.COMPANY
                                ? "fa-chevron-up"
                                : "fa-chevron-down"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.headerClick(
                                _vm.orderFilterEnum.COMPANY
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              !_vm.filterByRound
                ? _c("th", { staticClass: "text-left" }, [
                    _c(
                      "div",
                      { staticClass: "table-thead-flex" },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("single_round")) + " "),
                        _c("font-awesome-icon", {
                          staticClass: "arrow-table-icon",
                          attrs: {
                            color:
                              "" +
                              (_vm.changeIconOrder === _vm.orderFilterEnum.ROUND
                                ? "var(--primary)"
                                : "#AFAFAF"),
                            icon:
                              "fa-solid " +
                              (_vm.changeIconOrder === _vm.orderFilterEnum.ROUND
                                ? "fa-chevron-up"
                                : "fa-chevron-down"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.headerClick(_vm.orderFilterEnum.ROUND)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("vehicle_title_single")) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.VEHICLE
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.VEHICLE
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(_vm.orderFilterEnum.VEHICLE)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("th", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "table-thead-flex" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("status")) + " "),
                    _c("font-awesome-icon", {
                      staticClass: "arrow-table-icon",
                      attrs: {
                        color:
                          "" +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.STATUS
                            ? "var(--primary)"
                            : "#AFAFAF"),
                        icon:
                          "fa-solid " +
                          (_vm.changeIconOrder === _vm.orderFilterEnum.STATUS
                            ? "fa-chevron-up"
                            : "fa-chevron-down"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.headerClick(_vm.orderFilterEnum.STATUS)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              !_vm.isManagerUser ? _c("th") : _vm._e(),
            ]),
            _c(
              "tbody",
              { staticClass: "interest-table-body" },
              _vm._l(_vm.allocations, function (allocation, i) {
                return _c("tr", { key: i }, [
                  _c(
                    "td",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goInvestorProfile(allocation.investorId)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          style:
                            "" +
                            (allocation.investorId
                              ? "text-decoration: underline"
                              : "text-decoration: none"),
                          attrs: { id: "interest-table-body-investor-name" },
                        },
                        [_vm._v(" " + _vm._s(allocation.investorName) + " ")]
                      ),
                    ]
                  ),
                  _c("td", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatDate(allocation.startDate))),
                    ]),
                  ]),
                  _c("td", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatCurrency(
                            allocation.currency,
                            allocation.value
                          )
                        )
                      ),
                    ]),
                  ]),
                  !_vm.filterByRound
                    ? _c("td", [
                        _c("span", [_vm._v(_vm._s(allocation.companyName))]),
                      ])
                    : _vm._e(),
                  !_vm.filterByRound
                    ? _c("td", [
                        _c("span", [_vm._v(_vm._s(allocation.roundId))]),
                      ])
                    : _vm._e(),
                  _c("td", [
                    allocation.vehicleName
                      ? _c("span", [_vm._v(_vm._s(allocation.vehicleName))])
                      : _c("span", [_vm._v("-")]),
                  ]),
                  _c("td", [
                    _c(
                      "span",
                      {
                        style:
                          "color: " +
                          _vm.selectedAllocationStatusColor(allocation.status),
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              _vm.selectedAllocationStatus(allocation.status)
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                  !_vm.isManagerUser
                    ? _c(
                        "td",
                        {
                          attrs: { id: "interests-table-body-see-details" },
                          on: {
                            click: function ($event) {
                              return _vm.goToAllocationDetails(allocation.id)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "fa-solid fa-eye" },
                          }),
                          _c("span", [_vm._v(" Detalhes ")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
      !_vm.loading
        ? _c(VPagination, {
            staticClass: "mt-6",
            attrs: { color: "primary", length: _vm.pageCount },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }